import { PatientTreatment } from '@allurion/domain';
import { dateAdd, toDateStr } from '@allurion/utils';

export function hasAllurionBallonTreatment(treatments: PatientTreatment[]) {
  return treatments.find(({ TreatmentTypeID }) => isAllurionBallonTreatment(TreatmentTypeID));
}

export function isAllurionBallonTreatment(treatmentCategoryId?: number | string) {
  return treatmentCategoryId?.toString() === '1';
}

export function isValidStartDate(startDateStr: string) {
  const today = new Date();
  const maxDate = toDateStr(dateAdd(today, 90));

  return startDateStr <= maxDate;
}
